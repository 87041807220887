<template>
  <v-app>
    <v-snackbar v-model="alert.show" :color="alert.color" top>
      <v-row class="py-0 my-0">
        <v-col cols="12" md="11" class="py-0 my-0">
          {{ alert.message }}
        </v-col>
        <v-col cols="12" md="1" class="py-0 my-0">
          <v-icon small dark @click="alert.show = false"> {{ icons.mdiClose }} </v-icon>
        </v-col>
      </v-row>
    </v-snackbar>

    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <v-text-field
            rounded
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <!-- <theme-switcher></theme-switcher> -->
          
          <app-bar-chat-notifier
            :unseenMsg="unseenMsg"
          />

          <app-bar-notification
            :notifications="notifications"
          />

          <app-bar-user-menu
            :usertId="chatId"
            :account="account"
            :avatar="photo"
          />
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          {{ footer_txt }}
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mdiMagnify, mdiBellOutline, mdiGithub, mdiClose } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarNotification from './components/AppBarNotification.vue'
import AppBarChatNotifier from './components/AppBarChatNotifier.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import { howLongAgo, secondsTolocal } from "@/utils/dates"


export default {
  name: "contents",
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarNotification,
    AppBarChatNotifier,
    AppBarUserMenu,
  },

  data() {
    return {
      howLongAgo,
      secondsTolocal,
      adminId: "",
      chatId: "",
      account: "",
      photo: "",
      unseenMsg: [],
      notifications: [],
      currentRoute: "",
      timer: null,
      isDrawerOpen: true,
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiClose,
      },
      footer_txt: "",
    }
  },

  mounted(){
  },

  computed: {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
