<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        :color="(notifications.length>0 ? 'error' : 'secondary')"
        overlap
        offset-x="10"
        offset-y="20"
        class="ms-4"
        dot
      >
        <v-icon v-bind="attrs" v-on="on">
          {{ mdiBellOutline }}
        </v-icon>
      </v-badge>
    </template>

    <v-list v-if="notifications.length>0" subheader dense twoLine nav class="pt-2">
      <template v-for="(item, index) in notifications">
        <v-list-item :key="item.id" style="height:3.2rem;">
          <v-list-item-avatar>
            <v-img :src="item.photo"></v-img>
          </v-list-item-avatar>

          <router-link :to="getLink(item)">
            <v-list-item-content @click="readNotification(index, item.id)">
              <v-list-item-title v-text="item.sender_name"></v-list-item-title>
              <v-list-item-subtitle
                class="text--primary"
                v-text="item.message"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </router-link>

          <v-list-item-action class="mt-1">
            <v-list-item-action-text
              class="pb-1"
              v-text="item.created_at">
            </v-list-item-action-text>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="(item.status=='unread'?'error':'secondary')"
                  @click="readNotification(index, item.id)"
                >{{mdiBellOutline}}</v-icon>
              </template>
              <span>Read</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="index < notifications.length - 1"
          :key="index"
        ></v-divider>
      </template>
    </v-list>
    
  </v-menu>
</template>

<script>
import { mdiBellOutline, mdiMessageOutline } from '@mdi/js'

export default {
  props: {
    notifications: {
      type: Array,
      default(){
        return []
      }
    },
  },

  data(){
    return {
      mdiBellOutline,
      mdiMessageOutline,
    }
  },

  methods: {

    getLink(item){
      let url = "#";
      let type = item.msg_type.split("|");
      
      if(type.length==3 && type[0]=='quotaion'){
        url = '/quotation-detail?uuid='+type[2];
      }
      return url;
    }
  }
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
