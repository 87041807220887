const zeroPad = (num, pad) => {
  return String(num).padStart(pad, '0')
}

const isSameDay = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

export const parseTimestamp = (timestamp, format = '') => {
  if (!timestamp) return

  const date = timestamp.seconds ? new Date(timestamp.seconds * 1000) : timestamp

  if (format === 'HH:mm') {
    return `${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`
  } else if (format === 'DD MMMM YYYY') {
    const options = { month: 'long', year: 'numeric', day: 'numeric' }
    return `${new Intl.DateTimeFormat('en-GB', options).format(date)}`
  } else if (format === 'DD/MM/YY') {
    const options = { month: 'numeric', year: 'numeric', day: 'numeric' }
    return `${new Intl.DateTimeFormat('en-GB', options).format(date)}`
  } else if (format === 'DD MMMM, HH:mm') {
    const options = { month: 'long', day: 'numeric' }
    return `${new Intl.DateTimeFormat('en-GB', options).format(
      date
    )}, ${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`
  }

  return date
}

export const formatTimestamp = (date, timestamp) => {
  const timestampFormat = isSameDay(date, new Date()) ? 'HH:mm' : 'DD/MM/YY'
  const result = parseTimestamp(timestamp, timestampFormat)
  return timestampFormat === 'HH:mm' ? `Today, ${result}` : result
}

export const dateDiff = {
  inSeconds: function(d1, d2){
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2-t1)/1000);
  },
  inMinutes: function(d1, d2){
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2-t1)/60000);
  },
  inHours: function(d1, d2){
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2-t1)/3600000);
  },
  inDays: function(d1, d2){
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2-t1)/(24*3600*1000));
  },
  inWeeks: function(d1, d2){
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2-t1)/(24*3600*1000*7));
  },
  inMonths: function(d1, d2){
    var d1Y = d1.getFullYear();
    var d2Y = d2.getFullYear();
    var d1M = d1.getMonth();
    var d2M = d2.getMonth();

    return (d2M+12*d2Y)-(d1M+12*d1Y);
  },
  inYears: function(d1, d2){
    return d2.getFullYear()-d1.getFullYear();
  }
}

export const howLongAgo = (past) => {
  past = new Date(past);

  let inSeconds = dateDiff.inSeconds(past, new Date());
  let inMinutes = dateDiff.inMinutes(past, new Date());
  let inHours = dateDiff.inHours(past, new Date());
  let inDays = dateDiff.inDays(past, new Date());
  let inWeeks = dateDiff.inWeeks(past, new Date());
  let inMonths = dateDiff.inMonths(past, new Date());
  let inYears = dateDiff.inYears(past, new Date());

  let ago = inSeconds + " sec";
  if(inMinutes < 60){
    ago = inMinutes + "  min";
  } else if(inHours < 24){
    ago = inHours + "  hr";
  } else if(inDays < 7){
    ago = inDays + "  day";
  } else if(inWeeks < 4){
    ago = inWeeks + "  week";
  } else if(inDays >= 30){
    ago = inMonths + "  month";
  } else if(inMonths >= 12){
    ago = inYears + "  year";
  }
  return ago
}

export const secondsTolocal = (secs) => {
  return new Date(new Date(secs * 1000)).toLocaleDateString(
    "en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }
  );
}